.app {
  text-align: center;
}

.scrollbar-y {
  float: left;
  overflow-y: scroll;
}

.scrollbar-x {
  float: left;
  overflow-x: scroll;
}

.scrollbar-gray,
.scrollbar-x,
.scrollbar-y {
  scrollbar-color: #b8c5d8 #f5f5f5;
}

.style-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.style-2::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.style-2::-webkit-scrollbar-thumb {
  background-color: #b8c5d8;
}

::-webkit-scrollbar {
  width: 12px;
  height: 14px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgb(160, 160, 160);
  background-color: #b8c5d8;
}

/* Calendar Scheduling */

.fc-daygrid-day {
  cursor: cell;
}

.fc-timegrid-event,
.fc-daygrid-event,
.fc-list-event {
  cursor: pointer;
}
